import React from 'react';
import primaryLogo from '../../images/primary_logo.png';

const Logo = props => {
  return (
    <img
      src={props.src || primaryLogo}
      alt="logo LinkBox"
      width={props.width}
    />
  );
};
export default Logo;
