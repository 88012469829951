const design = {
  breakpoints: {
    mobile: 0,
    tablet: 640,
    desktop: 960,
    desktopXL: 2000
  },
  colors: {
    dark: '#545454',
    white: '#FFFFFF',

    primaryBlue: '#0072B3',
    primaryBlueHover: '#3196CE',

    primaryOrange: '#FF6B2F',
    primaryOrangeHover: '#FF9970',

    primaryGreen: '#16BAC5',
    primaryGreenHover: '#73D1D8',
  },
};

export default design;
