import React from 'react';
import styled from 'styled-components';
import design from '../../config/design';
// import Icon from '../Icon/Icon';
// import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { View } from '../View/View';
import { Flex } from '../Container/Container';
import { Visible } from '../Visible/Visible';

import ReactMarkdownMain, * as ReactMarkdownAlt from 'react-markdown/with-html';
import Countdown from 'react-countdown';
import { BigView } from '../BigView/BigView';
// import moment from 'moment';

const ReactMarkdown = ReactMarkdownMain || ReactMarkdownAlt;

const StyledBanner = styled.div`
  background-color: ${design.colors.primaryGreen};
  color: ${design.colors.white};
  height: 37px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 20;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.03em;
  @media screen and (min-width: ${design.breakpoints.mobile}px) {
    justify-content: center;
  }
  @media screen and (min-width: ${design.breakpoints.tablet}px) {
    display: flex;
    justify-content: space-between;
  }
  .uppercase {
    text-transform: uppercase;
  }
  .weight-bold {
    font-weight: bold;
  }
  .icon {
    cursor: pointer;
    @media screen and (min-width: ${design.breakpoints.mobile}px) {
      padding-left: 15px;
    }
  }
  .link {
    text-decoration: none;
    color: ${design.colors.white};
  }
  p {
    a {
      color: ${design.colors.white};
    }
  }
`;

function Banner(props) {
  return (
    <>
      {props.banner.activeCampaignBanner ? (
        <>
          {' '}
          <StyledBanner>
            <BigView>
              <View
                mobileTop={0.5}
                mobileLeft={1}
                mobileRight={1}
                desktopLeft={6}
                desktopRight={6}
                tabletTop={0}
                tabletLeft={3}
                tabletRight={3}
              >
                <div>
                  <Flex>
                    <Visible desktop>
                      <Flex>
                        <p>
                          <ReactMarkdown
                            source={props.banner.bannerCampaignText}
                            escapeHtml={false}
                          />
                        </p>
                        {props.banner.showCountdownInBanner ? (
                          <>
                            <View left={0.25} />
                            {/* <Flex>
                            <span>Prazo para confirmação de interesse: </span>
                            <View left={0.25} />
                            <span className="weight-bold">
                              {moment(
                                Date.now() +
                                  parseInt(props.banner.countdownDate) * -1
                              ).format('DD/MM/YYYY')}{' '}
                            </span>
                            
                          </Flex> */}
                            <Countdown
                              date={
                                Date.now() +
                                parseInt(props.banner.countdownDate) * -1
                              }
                              renderer={props => (
                                <Flex>
                                  <span>
                                    Prazo para confirmação de interesse:{' '}
                                  </span>
                                  <View left={0.25} />
                                  <span className="weight-bold">
                                    {props.days} Dias, {props.hours} Horas,{' '}
                                    {props.minutes} minutos e {props.seconds}{' '}
                                    segundos
                                  </span>
                                </Flex>
                              )}
                            />
                          </>
                        ) : null}
                      </Flex>
                    </Visible>
                  </Flex>
                  <Visible mobile tablet style={{ textAlign: 'center' }}>
                    <p>
                      <ReactMarkdown
                        source={props.banner.bannerCampaignTextMobile}
                        escapeHtml={false}
                      />
                    </p>
                  </Visible>
                </div>
              </View>
            </BigView>
            {/* 
            <div className="icon">
              <Icon name={faTimes} bgColor="white" />
              <View left={8} />
            </div> 
            */}
          </StyledBanner>
        </>
      ) : null}
    </>
  );
}
export default Banner;
