import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import design from '../../config/design';
import Logo from '../Logo/Logo';
import { View } from '../View/View';
import { Flex, Spaced } from '../Container/Container';
import primaryLogo from '../../images/primary_logo.png';
import secondaryLogo from '../../images/secondary_logo.png';
import { Link as StyledLink } from 'gatsby';
import useWindowSize from '../../utils/useWindowSize';
import Icon from '../Icon/Icon';
import BurgerMenu from 'react-burger-menu';
import '../../components/Header/menuOverrides.css';
import {
  faBars,
  faShoppingCart,
  faTruck,
  faStore,
  faUsers,
  faBox,
  faHome,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { BigView } from '../BigView/BigView';

const StyledHeader = styled.div`
  background-color: ${props =>
    props.opacity ? '#F4F4F41A' : design.colors.primaryBlue};
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  height: 74px;
`;

const CloseMenuContainer = styled.div`
  display: flex;
  text-align: right;
  margin: 30px;
  outline: none;
`;

const Menu = BurgerMenu['slide'];

/* const Overlay = styled.div`
  height: ${props => (props.isActiveToggle ? '100%' : '0%')};

  width: 100%;
  position: fixed;
  z-index: 60;
  top: 0;
  left: 0;
  background-color: ${design.colors.primaryBlue};
  overflow-y: hidden;
  transition: 0.5s;
  margin-top: 111px;

  .overlay-content {
    position: relative;
    width: 100%;
    color: white;
    display: flex;
    flex-wrap: wrap;
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    text-decoration: none;

    margin-top: 30px;
  }
`; */

const LogoContainer = styled.div`
  padding-top: 14px;
`;
const IconContainer = styled.div`
  /* padding-top: 14px; */
  cursor: pointer;
  @media screen and (min-width: ${design.breakpoints.mobile}px) {
    display: flex;
  }

  @media screen and (min-width: ${design.breakpoints.desktop}px) {
    display: none;
  }
`;

const HeaderMenuLink = styled(StyledLink)`
  outline: none;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  color: ${design.colors.white};
  text-transform: uppercase;
  text-decoration: none;
  padding: 1rem;
  width: 100%;
  text-align: left;
`;

const Link = styled(StyledLink)`
  padding-top: 26px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0.14em;
  /* margin-right: ${props => (props.nomargin ? '0px' : '26px')}; */
  margin-right: 26px;
  color: ${design.colors.white};
  text-transform: uppercase;
  text-decoration: none;
  &:hover {
    color: ${design.colors.primaryGreenHover};
  }
  @media screen and (min-width: ${design.breakpoints.mobile}px) {
    display: none;
  }

  @media screen and (min-width: ${design.breakpoints.desktop}px) {
    display: flex;
  }
`;

function Header(props) {
  const [color, setColor] = useState(false);
  const [logoType, setLogoType] = useState(secondaryLogo);
  const [opacityMode, setOpacityMode] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  // Effect created for listen changes in header
  const size = useWindowSize();

  const handleCloseMenu = () => {
    setMenuIsOpen(false);
  };

  const handleStateChange = state => {
    setMenuIsOpen(state.isOpen);
  };

  useEffect(() => {
    if (window.location.pathname === '/') {
      if (size.width <= 959) {
        setColor(false);
        setLogoType(secondaryLogo);
        setOpacityMode(false);
      } else {
        setColor(true);
        setLogoType(primaryLogo);
        setOpacityMode(true);
      }
    }
  }, [color, size.width]);
  return (
    <StyledHeader opacity={opacityMode}>
      <BigView>
        <View
          desktopLeft={6}
          desktopRight={6}
          mobileLeft={1}
          mobileRight={1}
          tabletLeft={3}
          tabletRight={3}
        >
          <Spaced>
            <Spaced>
              <LogoContainer>
                <StyledLink to={props.homepage.href}>
                  <Logo src={logoType} width="50px" />
                </StyledLink>
              </LogoContainer>
            </Spaced>
            <Flex>
              {props.items.map((item, i) => (
                <Link
                  key={i}
                  to={item.href}
                  // noMargin={props.items.length - 1 === i ? true : false}
                >
                  {item.label}
                </Link>
              ))}
            </Flex>
            <IconContainer>
              <div style={{ paddingTop: '14px' }}>
                <Icon big name={faBars} />
              </div>
              <Menu
                id={'slide'}
                pageWrapId={'page-wrap'}
                outerContainerId={'outer-container'}
                width={240}
                right={'right'}
                className={'menu__container'}
                isOpen={menuIsOpen}
                onStateChange={state => handleStateChange(state)}
              >
                <CloseMenuContainer onClick={handleCloseMenu}>
                  <Icon regular name={faTimes} />
                </CloseMenuContainer>
                <HeaderMenuLink to={'/'}>
                  <Icon regular name={faHome} />
                  <span> Home</span>
                </HeaderMenuLink>
                <HeaderMenuLink to={'/produtos'}>
                  <Icon regular name={faShoppingCart} />
                  <span> Produtos</span>
                </HeaderMenuLink>
                <HeaderMenuLink to={'/campanhas'}>
                  <Icon regular name={faStore} />
                  <span> Campanhas</span>
                </HeaderMenuLink>
                <HeaderMenuLink to={'/fornecedores'}>
                  <Icon regular name={faTruck} />
                  <span> Fornecedores</span>
                </HeaderMenuLink>
                <HeaderMenuLink to={'/clientes'}>
                  <Icon regular name={faUsers} />
                  <span> Clientes</span>
                </HeaderMenuLink>
                <HeaderMenuLink to={'/sobre'}>
                  <Icon regular name={faBox} />
                  <span> Sobre nós</span>
                </HeaderMenuLink>
              </Menu>
            </IconContainer>
            {/* {getMenu()} */}
            {/* <IconContainer onClick={props.handleToggle}>
            <Icon big name={faBars} />
          </IconContainer> */}
          </Spaced>
        </View>
      </BigView>
    </StyledHeader>
  );
}

export default Header;
