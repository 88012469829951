import styled from 'styled-components';
import design from '../../config/design';

export const View = styled.div`
  @media screen and (min-width: ${design.breakpoints.mobile}px) {
    padding-top: ${props => props.mobileTop}rem;
    padding-left: ${props => props.mobileLeft}rem;
    padding-right: ${props => props.mobileRight}rem;
    padding-bottom: ${props => props.mobileBottom}rem;
    padding: ${props => props.mobileAll}rem;
  }
  @media screen and (min-width: ${design.breakpoints.tablet}px) {
    padding-top: ${props => props.tabletTop}rem;
    padding-left: ${props => props.tabletLeft}rem;
    padding-right: ${props => props.tabletRight}rem;
    padding-bottom: ${props => props.tabletBottom}rem;
    padding: ${props => props.tabletAll}rem;
  }
  @media screen and (min-width: ${design.breakpoints.desktop}px) {
    padding-top: ${props => props.desktopTop}rem;
    padding-left: ${props => props.desktopLeft}rem;
    padding-right: ${props => props.desktopRight}rem;
    padding-bottom: ${props => props.desktopBottom}rem;
    padding: ${props => props.desktopAll}rem;
  }
  padding-top: ${props => props.top}rem;
  padding-left: ${props => props.left}rem;
  padding-right: ${props => props.right}rem;
  padding-bottom: ${props => props.bottom}rem;
  padding: ${props => props.all}rem;

  display: ${props => (props.center ? 'flex' : null)};
  justify-content: ${props => (props.center ? 'center' : null)};
`;
