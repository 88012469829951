import zonaSul from '../../images/zonaSul.png';
import company1 from '../../images/company1.png';
import company2 from '../../images/company2.png';
import product1 from '../../images/mockprod.png';
import {
  faFacebookSquare,
  faWhatsapp,
  faLinkedinIn,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';

export const title = {
  content: 'Assista nosso vídeo e veja como funcionamos:',
};
export const text = {
  content:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Molestie vitae in fermentum aenean nunc scelerisque. Cursus bibendum mattis fusce tortor. Ut et et ac ullamcorper non amet. Faucibus dignissim venenatis mi suspendisse feugiat magna massa metus sed. Sagittis, et cursus suscipit nec non pharetra, hendrerit. Lobortis at quisque tortor tellus quam. Vel ornare leo nec, tincidunt tempus et. Mattis lectus urna at lorem elit in est. Etiam quisque consequat sed nisl lectus tincidunt tellus iaculis. Tristique justo varius imperdiet id sollicitudin varius sit. Orci amet dolor nulla nisi, lacus. Velit convallis at amet lacinia purus quisque varius enim. Volutpat nulla interdum cras donec leo. Commodo ullamcorper nunc integer nisl, pretium. Arcu placerat at tempus volutpat eleifend. Id lobortis amet pulvinar lacus id sit. Id feugiat magnis hac dui sed donec. Facilisis adipiscing nec arcu leo. Ipsum, sagittis, laoreet morbi interdum id nisi, convallis.',
};

export const buttonVariation1 = {
  content: 'quero fazer parte',
};

export const buttonVariation2 = {
  content: 'conheça nossos produtos!',
};

export const buttonRoundedVariation = {
  content: 'saiba mais',
};

export const buttonRoundedBlockVariation = {
  content: 'garantir meu produto',
};
export const subtitle = {
  content: 'Você está a poucos passos de economizar utilizando a LinkBox',
};
export const clientCard = {
  img: zonaSul,
  title: 'Zona sul - Cariocas de coração',
  shortDescrition: 'pequeno texto sobre o cliente',
};

export const listClientCard = [
  {
    img: zonaSul,
    title: 'Zona sul - Cariocas de coração',
    shortDescrition: 'pequeno texto sobre o cliente',
  },

  {
    img: company1,
    title: 'Leão - leão',
    shortDescrition: 'pequeno texto sobre o cliente',
  },
  {
    img: company2,
    title: 'Tv brasil - Lorem lorem lorem lorem lorem lorem lorem',
    shortDescrition:
      'Lorem lorem lorem **lorem lorem lorem** lorem Lorem lorem lorem lorem lorem lorem lorem Lorem lorem lorem lorem lorem lorem lorem Lorem lorem lorem lorem lorem lorem lorem',
  },
];
export const campaign = {
  bgColor: '#FF6B2F',
  content: 'Em campanha',
};

export const productCard = {
  img: product1,
  title: 'Azeite Mikonos',
  shortDescrition: 'Short description Lorem ipsum dolor sit amet...',
};
export const productCardItemsCampaign = [
  {
    img: product1,
    isActiveCampaign: true,
    title: 'Azeite Mikonos',
    shortDescrition:
      'Short description Lorem ipsum dolor sit amet...Short description Lorem ipsum dolor sit amet...Short description Lorem ipsum dolor sit amet...Short description Lorem ipsum dolor sit amet...Short description Lorem.',
  },
  {
    img: 'https://www.datocms-assets.com/29784/1594266169-campanha2-edited.png',
    title: 'Azeite Mikonos',
    isActiveCampaign: true,
    shortDescrition: 'Short description Lorem ipsum dolor sit amet...',
  },
  {
    img: product1,
    title: 'Azeite Mikonos',
    isActiveCampaign: true,
    shortDescrition: 'Short description Lorem ipsum dolor sit amet...',
  },
  {
    img: product1,
    title: 'Azeite Mikonos',
    isActiveCampaign: true,
    shortDescrition: 'Short description Lorem ipsum dolor sit amet...',
  },
];
export const productCardItems = [
  {
    img: product1,
    title: 'Azeite Mikonos',
    shortDescrition: 'Short description Lorem ipsum dolor sit amet...',
  },
  {
    img: product1,
    title: 'Azeite Mikonos',
    shortDescrition: 'Short description Lorem ipsum dolor sit amet...',
  },
  {
    img: product1,
    title: 'Azeite Mikonos',
    shortDescrition: 'Short description Lorem ipsum dolor sit amet...',
  },
  {
    img: product1,
    title: 'Azeite Mikonos',
    shortDescrition: 'Short description Lorem ipsum dolor sit amet...',
  },
  {
    img: product1,
    title: 'Azeite Mikonos',
    shortDescrition: 'Short description Lorem ipsum dolor sit amet...',
  },
  {
    img: product1,
    title: 'Azeite Mikonos',
    shortDescrition: 'Short description Lorem ipsum dolor sit amet...',
  },
  {
    img: product1,
    title: 'Azeite Mikonos',
    shortDescrition: 'Short description Lorem ipsum dolor sit amet...',
  },
  {
    img: product1,
    title: 'Azeite Mikonos',
    shortDescrition: 'Short description Lorem ipsum dolor sit amet...',
  },
  {
    img: product1,
    title: 'Azeite Mikonos',
    shortDescrition: 'Short description Lorem ipsum dolor sit amet...',
  },
  {
    img: product1,
    title: 'Azeite Mikonos',
    shortDescrition: 'Short description Lorem ipsum dolor sit amet...',
  },
  {
    img: product1,
    title: 'Azeite Mikonos',
    shortDescrition: 'Short description Lorem ipsum dolor sit amet...',
  },
  {
    img: product1,
    title: 'Azeite Mikonos',
    shortDescrition: 'Short description Lorem ipsum dolor sit amet...',
  },
];

export const banner = {
  link: '',
  stopwatch: {
    days: 5,
    hours: 3,
    minutes: 37,
  },
};

export const form = {
  about: [
    {
      content: 'Produtos',
      link: '/produtos',
    },
    {
      content: 'Campanhas',
      link: '/campanhas',
    },
    {
      content: 'Sobre nós',
      link: '/sobre',
    },
  ],
  joinUs: [
    {
      content: 'Fornecedores',
      link: '/fornecedores',
    },
    {
      content: 'Clientes',
      link: '/clientes',
    },
  ],
  contact: [
    {
      icon: faWhatsapp,
      link:
        'https://api.whatsapp.com/send?phone=${encodeURIComponent(5511963624923)}&text=Olá, gostaria de saber mais sobre o LinkBOX.',
    },
    {
      icon: faLinkedinIn,
      link: 'https://www.linkedin.com/company/53447026/',
    },

    {
      icon: faInstagram,
      link: 'https://www.instagram.com/importacao.compartilhada/',
    },
    {
      icon: faFacebookSquare,
      link: 'https://www.facebook.com/linkbox.importacao.compartilhada',
    },
  ],
};
export const countdown = {
  stopwatch: {
    days: 5,
    hours: 3,
    minutes: 37,
  },
  dateOperation: '25/07/2020',
  arrivalProdutsinBrazil: '15/09/2020',
  placeArrival: 'Porto de Itajaí',
};
