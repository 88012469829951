export const headerItems = [
  {
    label: 'Home',
    href: '/',
  },
  {
    label: 'Produtos',
    href: '/produtos',
  },
  {
    label: 'Campanhas',
    href: '/campanhas',
  },
  {
    label: 'Fornecedores',
    href: '/fornecedores',
  },
  {
    label: 'Clientes',
    href: '/clientes',
  },
  {
    label: 'Sobre Nós',
    href: '/sobre',
  },
];

export const homeItem = {
  label: 'Homepage',
  href: '/',
};
