import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import GlobalStyles from '../GlobalStyles/GlobalStyles';
import { headerItems, homeItem } from '../../routes/header';
import styled from 'styled-components';
import Footer from '../Footer/Footer';
import { form } from '../../config/mocks/styleGuide.mock';
import Banner from '../Banner/Banner';
import ReactNotification from 'react-notifications-component';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Helmet from 'react-helmet';
import sharedBanner from '../../images/linkbox-share.png';

import { useStaticQuery, graphql } from 'gatsby';

const StyledLayout = styled.div`
  position: ${props => (props.isActiveToggle ? 'fixed' : 'relative')};
`;

/* The LayoutWrapper encapsulates all pages */
export default function LayoutWrapper({ children }) {
  const [toggle, setToggle] = useState(false);
  const handleToggle = () => {
    setToggle(!toggle);
    if (toggle) {
      sessionStorage.setItem('@toggle', true);
    } else {
      sessionStorage.setItem('@toggle', false);
    }
  };
  // Integrate CRISP chat in our project
  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = 'b9edb536-a338-4782-b3d0-d2f6a4d61382';

    (function() {
      let d = document;
      let s = d.createElement('script');

      s.src = 'https://client.crisp.chat/l.js';
      s.async = 1;
      d.getElementsByTagName('head')[0].appendChild(s);
    })();
  }, []);

  /* CMS Data */
  const { data, metatags } = useStaticQuery(
    graphql`
      query {
        data: allDatoCmsProductConfig {
          nodes {
            activeCampaignBanner
            bannerCampaignText
            bannerCampaignTextMobile
            showCountdownInBanner
            countdownDate(difference: "milliseconds")
          }
        }
        metatags: datoCmsSite {
          faviconMetaTags {
            tags
          }
        }
      }
    `
  );
  const bannerInfo = data.nodes[0];

  return (
    <>
      <Helmet>
        <meta property="og:image" content={sharedBanner} />
        <meta property="og:title" content="LinkBOX" />
        <meta property="og:url" content="https://www.linkbox.com.br" />
        <meta property="og:type" content="product" />
      </Helmet>
      <StyledLayout isActiveToggle={toggle}>
        <HelmetDatoCms favicon={metatags.faviconMetaTags} />
        <ReactNotification />
        <GlobalStyles />
        <Banner banner={bannerInfo} />
        <Header
          items={headerItems}
          homepage={homeItem}
          handleToggle={handleToggle}
          toggle={toggle}
        />
        {children}
        <Footer
          about={form.about}
          joinUs={form.joinUs}
          contact={form.contact}
        />
      </StyledLayout>
    </>
  );
}
