import styled from 'styled-components';
import design from '../../config/design';

export const BigView = styled.div`
  @media screen and (min-width: ${design.breakpoints.mobile}px) {
    margin: 0;
  }
  @media screen and (min-width: ${design.breakpoints.tablet}px) {
    margin: 0;
  }
  @media screen and (min-width: ${design.breakpoints.desktop}px) {
    margin: 0;
  }
  @media screen and (min-width: ${design.breakpoints.desktopXL}px) {
    margin: 0 400px;
  }
`;
