import React from 'react';
import styled from 'styled-components';
import design from '../../config/design';
import Icon from '../Icon/Icon';
import { View } from '../View/View';
import { Link as StyledLink } from 'gatsby';
import { BigView } from '../BigView/BigView';

const Link = styled(StyledLink)`
  text-decoration: none;
  &::hover {
    text-decoration: underline;
  }
`;

const StyledFooter = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  .footer-copyRight {
    color: ${design.colors.white};
    display: flex;
    justify-content: left;

    @media screen and (min-width: ${design.breakpoints.mobile}px) {
      font-size: 9px;
      line-height: 12px;
    }
    @media screen and (min-width: ${design.breakpoints.tablet}px) {
      font-size: 12px;
      line-height: 15px;
    }
  }
  hr {
    background-color: ${design.colors.white};
    width: 100%;
    margin-top: 30px;
    margin-bottom: 40px;
    opacity: 0.2;
  }

  .footer-distributed {
    background-color: ${design.colors.primaryBlue};
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    @media screen and (min-width: ${design.breakpoints.mobile}px) {
      font-size: 12px;
      line-height: 16px;
    }
    @media screen and (min-width: ${design.breakpoints.tablet}px) {
      font-size: 18px;
      line-height: 22px;
    }

    padding: 55px 50px;
    margin-top: 80px;
  }

  .footer-distributed .footer-left,
  .footer-distributed .footer-center,
  .footer-distributed .footer-right {
    display: inline-block;
    vertical-align: top;
  }

  .footer-distributed .footer-left {
    @media screen and (min-width: ${design.breakpoints.mobile}px) {
      width: 60%;
    }
    @media screen and (min-width: ${design.breakpoints.tablet}px) {
      width: 60%;
    }
    @media screen and (min-width: ${design.breakpoints.desktop}px) {
      width: 40%;
    }
  }

  .footer-distributed .footer-links {
    color: ${design.colors.white};
    margin: 20px 0 12px;
    padding: 0;
  }

  .footer-distributed .footer-links a {
    display: inline-block;
    line-height: 1.8;
    text-decoration: none;
    color: inherit;
    &::hover {
      text-decoration: underline;
    }
  }

  .footer-distributed .footer-center {
    width: 35%;
  }

  .footer-distributed .footer-right {
    width: 15%;
  }

  .footer-distributed .footer-icons {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
  }

  .footer-distributed h3 {
    color: ${design.colors.white};
    font-weight: bold;
    @media screen and (min-width: ${design.breakpoints.mobile}px) {
      font-size: 13px;
      line-height: 14px;
    }
    @media screen and (min-width: ${design.breakpoints.tablet}px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
`;

const ContactText = styled.div`
  color: white;
  font-size: 0.7rem;
  padding-bottom: 1rem;
  white-space: nowrap;
  a {
    color: white;
    font-weight: bold;
  }
  .phone {
    text-decoration: none;
  }
`;

const Footer = props => {
  return (
    <StyledFooter>
      <footer className="footer-distributed">
        <BigView>
          <View
            tabletLeft={2}
            tabletRight={2}
            tabletTop={1}
            desktopLeft={4}
            desktopRight={4}
          >
            <div className="footer-left">
              <h3>LinkBOX</h3>

              {props.about.map(item => (
                <p className="footer-links">
                  <Link to={item.link}>{item.content}</Link>
                </p>
              ))}
            </div>
            <div className="footer-center">
              <h3>Faça parte</h3>

              {props.joinUs.map(item => (
                <p className="footer-links">
                  <a href={item.link}>{item.content}</a>
                </p>
              ))}
            </div>

            <div className="footer-right">
              <View mobileTop={2} desktopTop={0} tabletTop={0} />
              <h3 style={{ whiteSpace: 'nowrap' }}>Contato</h3>

              <div className="footer-icons">
                {props.contact.map(item => (
                  <>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon name={item.icon} regular />
                    </a>
                    <View left={2} />
                  </>
                ))}
              </div>
              <View top={2} />
              <ContactText>
                <a href="mailto:contato@linkbox.com.br">
                  contato@linkbox.com.br
                </a>
              </ContactText>
              <ContactText>
                <a className="phone" href="tel:+5511984849040">
                  (11) 98484-9040
                </a>
              </ContactText>
              <ContactText>
                <a className="phone" href="tel:+5511963625162">
                  (11) 96362-5162
                </a>
              </ContactText>
              <ContactText>
                <a className="phone" href="tel:+5511963624923">
                  (11) 96362-4923
                </a>
              </ContactText>
            </div>

            <hr />

            <p className="footer-copyRight">
              &copy; {new Date().getFullYear()} - LinkBOX - Todos os direitos
              reservados.
            </p>
          </View>
        </BigView>
      </footer>
    </StyledFooter>
  );
};

export default Footer;
