import styled from 'styled-components';
import design from '../../config/design';

export const Flex = styled.div`
  display: flex;
  justify-content: ${props => (props.center ? 'center' : null)};
`;
export const FlexWrap = styled.div`
  @media screen and (min-width: ${design.breakpoints.mobile}px) {
    display: flex;
    flex-wrap: wrap;
  }
  @media screen and (min-width: ${design.breakpoints.tablet}px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const Spaced = styled.div`
  display: flex;
  justify-content: ${props =>
    props.around ? 'space-around' : 'space-between'};
`;

export const Container = styled.div``;
