import React from 'react';
import styled from 'styled-components';
import design from '../../config/design';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledIcon = styled(FontAwesomeIcon)`
  font-style: normal;
  font-weight: 900;
  color: ${props =>
    props.big || props.regular ? design.colors.white : props.bgColor};
  @media screen and (min-width: ${design.breakpoints.mobile}px) {
    bottom: 12.5%;
    font-size: ${props =>
      props.big ? '2.5rem' : props.regular ? '20px' : '12px'};
    line-height: 14px;
  }
  @media screen and (min-width: ${design.breakpoints.tablet}px) {
    bottom: 18.18%;
    font-size: ${props =>
      props.big ? '2.5rem' : props.regular ? '24px' : '16px'};
    line-height: 18px;
  }
`;

const Icon = props => {
  return (
    <StyledIcon
      icon={props.name}
      big={props.big}
      regular={props.regular}
      bgColor={props.bgColor}
    />
  );
};

export default Icon;
