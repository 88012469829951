import React from 'react';
import styled from 'styled-components';
import design from '../../config/design';
import ReactMarkdownMain, * as ReactMarkdownAlt from 'react-markdown/with-html';

const ReactMarkdown = ReactMarkdownMain || ReactMarkdownAlt;

const StyledTitle = styled.h1`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  color: ${props => props.color || design.colors.primaryBlue};
  @media screen and (min-width: ${design.breakpoints.mobile}px) {
    font-size: ${props => (props.big ? '24px' : '18px')};
    line-height: auto;
  }
  @media screen and (min-width: ${design.breakpoints.tablet}px) {
    font-size: ${props => (props.big ? '42px' : '24px')};
    line-height: auto;
  }
  @media screen and (min-width: ${design.breakpoints.desktop}px) {
    font-size: ${props => (props.big ? '48px' : '32px')};
    line-height: auto;
  }
`;

const Title = props => {
  return (
    <StyledTitle color={props.color} big={props.big}>
      <ReactMarkdown source={props.content} escapeHtml={false} />
    </StyledTitle>
  );
};
export default Title;
